import React, { useState } from 'react';
import RichText from '../../components/rich-text';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import countries from '../../components/contact-module/countries.json';
import ReCaptcha, { validateForSubmit } from '../../components/contact-module/recaptcha';
import './index.scss';

const FORM_ENDPOINT = `${process.env.API_BASE}/newsletter-signup`;

export default function NewsletterSignupForm ({ formHeading, successMessage }) {
  const [ showSuccessMessage, setShowSuccessMessage ] = useState(false);
  const [ submitting, setSubmitting ] = useState(false);

  const handleSubmit = (ev) => {
    ev.preventDefault();

    const formEl = ev.target;

    async function _handleSubmit () {
      setSubmitting(true);

      const recaptchaData = await validateForSubmit('submit_newsletter_signup_form');

      const data = { ...recaptchaData };

      for (let el of formEl) {
        if (!el.name?.length || !el.value?.length) continue;
        data[el.name] = el.value;
      }

      const res = await fetch(FORM_ENDPOINT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        referrerPolicy: 'no-referrer-when-downgrade',
        body: JSON.stringify(data)
      });

      if (!res.ok) {
        setSubmitting(false);
        const errBody = await res.text();
        throw new Error(`Form submission failed ${res.status}: ${errBody}`);
      }

      setShowSuccessMessage(true);
      setSubmitting(false);
    }

    _handleSubmit().catch(console.error);
  };

  const legalCheckbox = (
    <Checkbox
      name="newsletter-signup-form-terms-accepted"
      color="primary"
      required={true}
      value="Yes"
    />
  );

  if (showSuccessMessage) {
    return (
      <div className="newsletter-signup-form">
        <h4>{formHeading}</h4>
        <div className="success-message">
          <RichText node={successMessage} />
        </div>
      </div>
    );
  }

  return (
    <ReCaptcha>
      <form className="newsletter-signup-form" onSubmit={handleSubmit}>
        <h4>{formHeading}</h4>

        <div className="form-field-half-width">
          <TextField
            id="newsletter-signup-form-first-name"
            label="Your first name"
            name="newsletter-signup-form-first-name"
            required={true}
            fullWidth={true}
          />
        </div>
        <div className="form-field-half-width">
          <TextField
            id="newsletter-signup-form-last-name"
            label="Your last name"
            name="newsletter-signup-form-last-name"
            required={true}
            fullWidth={true}
          />
        </div>
        <TextField
          id="newsletter-signup-form-company"
          label="Your company"
          name="newsletter-signup-form-company"
          required={true}
          fullWidth={true}
        />
        <TextField
          id="newsletter-signup-form-email"
          label="Your email address"
          name="newsletter-signup-form-email"
          required={true}
          fullWidth={true}
          type="email"
        />
        <TextField
          id="newsletter-signup-form-country"
          label="Your country"
          name="newsletter-signup-form-country"
          required={true}
          fullWidth={true}
          select
          SelectProps={{ native: true }}
        >
          <option key="null"></option>
          {countries.map((c, i) => (<option key={i}>{c}</option>))}
        </TextField>
        <FormControlLabel
          control={legalCheckbox}
          label="By checking this box, you are agreeing that NDT Global is allowed to add you to their subscription database and send you marketing materials."
          required={true}
          color="primary"
        />

        <button
          className="newsletter-signup-form-submit"
          type="submit"
          disabled={submitting}
        >
          Submit
        </button>
      </form>
    </ReCaptcha>
  );
}
